<div class="wrapper">
    
                  <!-- Aside End -->
                  <main class="middle-content">
                      <!-- Page Title Start -->
                      <div class="page_title_block">
                          <h1 class="page_title" style="color:black"> VIEW ANNOUNCEMENT</h1>
                         
                      </div>
                      <!-- Page Title End -->

                      <div class="content-section">
                          <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                              <div class="main-block-innner mb40 mt40">
                                  <div class="add-store-block input-style">
                                    <!-- image upload start-->
                                     <div class="user-profile">
                                                <div class="image-box">
                                                    <img [src]="editImage || 'assets/img/testimonial-img1.jpg' " class="profile-pic" alt="profile image">
                                                   
                                                </div>
                                            </div>
                                            <!-- image upload end -->
                                      <div class="form-group row ">
                                          <label class="col-md-4">Page Name :</label>
                                          <div class="col-md-8">
                                              <p>{{editData?.title}}</p>
                                          </div>

                                      </div>
                                      <div class="form-group row">
                                  
                                          <label class="col-md-4">Description:</label>
                                          <div class="col-md-8" class="ex1" style="width: 66%;
                                          margin-left: 36%;" >
                                              <p>{{editData?.description}}</p>                                                    
                                        </div>
                                        
                                      </div>
                                      <div class="text-center mt40">
                                          <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/announcement-management']">Cancel</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </main>
                  <!-- Middle Content End -->
              </div>
              
              <footer>
                <div class="container-fluid">
                    <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
                        </p>
                </div>
            </footer>
