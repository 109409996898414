<div class="wrapper">
    
    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Terms & Conditions</h1>
           
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row ">
                            <label class="col-md-4">Page Name :</label>
                            <div class="col-md-8">
                                <textarea class="form-control common-textarea" [(ngModel)]="title" readonly></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description:</label>
                            <div class="col-md-8">
                                <ck-editor name="editor1"  [(ngModel)]="description" debounce="500"
                                skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                            </div>
                        </div>
                        <div class="text-center mt40">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green mr-2" (click)="updateLanguageData()">Update</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/static-content-management']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
            </p>
    </div>
</footer>