
<main class="middle-content">
                  <div class="page_title_block">
                      <h1 class="page_title" style="color:#26272d">WALLET MANAGEMENT</h1>
                  </div>
                  <div class="content-section">
                      <div class="outer-box">
                          <div class="global-table no-radius p0">
                              <div class="tab-content1">
                                  <div class="tab-pane1">
                                    <!-- ******************** This is the filter section start here ********************* -->
                                      <div class="user-main-head sec_head_new sec_head_new_after" >
                                          <form [formGroup]="userForm">
                                              <div class="row">
                                                <div class="col-md-2">
                                                  <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center" >Currency : </span>
                                                        <select name="" id="" class="w-100 form-control">
                                                          <option value="">BTC</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                </div>
                                                  <div class="col-md-5">
                                                      <div class="row">
                                                          <div class="col-md-5">
                                                              <div class="head_flt_select">
                                                                  <div class="head_flt_select input-label input-label1">
                                                                      <span class="d-flex align-items-center" >Date of Transaction : </span>
                                                                      <input type="date" formControlName="startdate">
                                                                  </div>
                                                              </div>
                
                                                          </div>
                                                          <div class="col-md-5">
                
                                                              <div class="head_flt_select">
                                                                  <div class="head_flt_select input-label input-label1">
                                                                      <span class="d-flex align-items-center">To Date : </span>
                                                                      <input type="date" formControlName="enddate">
                                                                  </div>
                                                              </div>
                
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-5">
                                                      <div class="user-form2">
                                                          <div class="row">
                                                              <div class="col-md-12 user-lst">
                                                                  <div class="two-btnline">
                                                                        <button type="submit" class="btn  btn-theme cus-mr" (click)="search()">SEARCH</button>
                                                                          <button type="submit" class="btn  ml-1 btn-theme cus-mr"
                                                                              >RESET</button>
                
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                      <!-- ******************** This is the filter section end here ********************* -->
                
                      <div class="custom_tabs common-tabs">
                        <div class="row mb20">
                          <div class="col-sm-3">
                
                          </div>
                            <div class="col-sm-6">
                                <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                                            [ngClass]="{'active': currTab=='Deposite'}" (click)="selectTab('Deposite')"
                                            href="javascript:;">Deposite</a>
                                    </li>
                                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Withdraw'}" (click)="selectTab('Withdraw')"
                                href="javascript:;">Withdraw</a>
                                   </li>
                                </ul>
                            </div>
                            <div class="col-sm-3 text-right">
                              <div class="head_flt_select">
                                <div class="head_flt_select input-label">
                                    <button type="submit" class="btn  btn-theme">EXPORT AS EXCEL </button>
                                </div>
                            </div>
                            </div>
                        </div>
                      </div>
                
                      <div class="table-responsive">
                        <table class="table table-bordered" aria-describedby="user list">
                            <thead>
                                <tr class="no_wrap_th">
                                    <th scope="col">ID</th>
                                    <th scope="col">Currency</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Transaction Hash</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of depositeData | paginate:{itemsPerPage: 20, currentPage: pageNumber,totalItems: depositeData?.length} ; let i=index;">
                                    <td>1</td>
                                    <td>BTC</td>
                                    <td>Deposite</td>
                                    <td>01/07/2020</td>
                                    <td>Txxx0001</td>
                                    <td>$2000</td>
                                    <td>Successfull</td>
                                </tr>
                                <tr *ngIf="depositeData?.length==0">
                                    <td colspan="10" vertical-align="middle">
                                        <div class="no-record">
                                            <div class="no-recordin">
                                                <H5 style="margin-left: 44%;">No record found</H5>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" *ngIf="depositeData?.length>20">
                        <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                    </div>
                
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </main>
                
                <footer>
                    <div class="container-fluid">
                        <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
                            </p>
                    </div>
                </footer>
                
                
                <!-- delete_modal Start -->
                <div class="modal fade global-modal reset-modal" id="deleteModal">
                  <div class="modal-dialog max-WT-500">
                      <form class="change_password">
                          <div class="modal-content">
                              <div>
                                  <div class="modal-body">
                                      <h3 style="text-align: center; ">Remove User </h3>
                                      <hr>
                                      <button type="button" class="close" data-dismiss="modal"
                                          style="margin-top: -85px;">&times;</button>
                                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                          <div class="w-100">
                                              <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                              <div>
                                                  <button type="submit" class="btn btn-info mr-2"
                                                      >Yes</button>
                                                  <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
                </div>
                <!-- delete_modal End -->
                
                <!-- block_modal Start -->
                <div class="modal fade global-modal reset-modal" id="block">
                  <div class="modal-dialog max-WT-500">
                      <form class="change_password">
                          <div class="modal-content">
                              <div>
                                  <div class="modal-body">
                                      <h3 style="text-align: center; ">Block User </h3>
                                      <hr>
                                      <button type="button" class="close" data-dismiss="modal"
                                          style="margin-top: -85px;">&times;</button>
                                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                          <div class="w-100">
                                              <p style="font-size: 25px;">Are you sure you want to block this user?</p>
                                              <div>
                                                  <button type="submit" class="btn btn-success mr-2"
                                                      >Yes</button>
                                                  <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
                </div>
                
                <!-- active Start -->
                <div class="modal fade global-modal reset-modal" id="active">
                  <div class="modal-dialog max-WT-500">
                      <form class="change_password">
                          <div class="modal-content">
                              <div>
                                  <div class="modal-body">
                                      <h3 style="text-align: center; ">Active User </h3>
                                      <hr>
                                      <button type="button" class="close" data-dismiss="modal"
                                          style="margin-top: -85px;">&times;</button>
                                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                          <div class="w-100">
                                              <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                              <div>
                                                  <button type="submit" class="btn btn-success mr-2"
                                                      >Yes</button>
                                                  <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
                </div>
                