<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">MINIMUM WITHDRAWAL AMOUNT  UPDATE</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                      
                            <div class="col-md-3">
                                <div class="head_flt_select">
                                    <div>
                                        <span class="labelcss"> Withdrawal Amount:</span>
                                        <input type="number" class="form-control" [(ngModel)]="withdrawlAmount"
                                            class="formcss">
                                        <h3 style="margin-left: 295%;margin-top: -17%;">%</h3>
                                       
                                    </div>
                                </div>
                            </div>
                           
                            <button type="submit" class="btn  btn-theme cus-mr"style="width: 33%;margin-left: 32%;margin-top: 5%;" (click)="submitAmount()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
            </p>
    </div>
</footer>