
<div class="wrapper">
  <main class="middle-content">
      <div class="page_title_block">
          <h1 class="page_title" style="color:#26272d">DASHBOARD</h1>
      </div>
      <div class="content-section dashboard-block">
          <ul class="dash_list d-flex w-100 flex-wrap text-center ">
              <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                  <div class="w-100">
                      <div class="dash_icn">
                          <i class="fas fa-users fa-3x" aria-hidden="true"></i>
                      </div>
                      <h2>Total Registered Users</h2>
                      <h4>{{TotalUserCount}}</h4>
                  </div>
              </li>
              <li class="d-inline-flex align-items-center justify-content-center bg-green">
                  <div class="w-100">
                      <div class="dash_icn">
                          <i class="fas fa-user-ninja fa-3x" aria-hidden="true"></i>
                      </div>
                      <h2>Total Active Users</h2>
                      <h4>{{activeUserLength}}</h4>
                  </div>
              </li>
              <li class="d-inline-flex align-items-center justify-content-center bg-red">
                  <div class="w-100">
                      <div class="dash_icn">
                          <i class="fas fa-user-slash fa-3x" aria-hidden="true"></i>
                      </div>
                      <h2>Total Blocked Users</h2>
                      <h4>{{blockedUserLength}}</h4>
                  </div>
              </li>
              <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                  <div class="w-100">
                      <div class="dash_icn">
                          <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                      </div>
                      <h2>Total Pending KYC</h2>
                      <h4>{{kycpendingListlength}}</h4>
                  </div>
              </li>
              <li class="d-inline-flex align-items-center justify-content-center bg-green">
                  <div class="w-100">
                      <div class="dash_icn">
                          <i class="fas fa-users-cog fa-3x" aria-hidden="true"></i>
                      </div>
                      <h2>Total Deposit Request</h2>
                      <h4>{{totalDepositeCount}}</h4>
                  </div>
              </li>
              <li class="d-inline-flex align-items-center justify-content-center bg-red">
                  <div class="w-100">
                      <div class="dash_icn">
                          <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                      </div>
                      <h2>Total Coins</h2>
                      <h4>{{totalCoinCount}}</h4>
                  </div>
              </li>
              <li class="d-inline-flex align-items-center justify-content-center bg-red">
                <div class="w-100">
                    <div class="dash_icn">
                        <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                    </div>
                    <h2>Total Token Registered</h2>
                    <h4>50</h4>
                </div>
            </li>
          </ul>
      </div>
  </main>
</div>
<footer>
    <div class="container-fluid">
        <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
            </p>
    </div>
</footer>
