

<!-- jjbjfjbg -->
<div class="wrapper">
  
        <!-- Aside End -->
        <main class="middle-content">
            <!-- Page Title Start -->
            <div class="page_title_block">
                <h1 class="page_title" style="color:black"> SETTINGS</h1>
               
            </div>
            <!-- Page Title End -->
            <div class="content-section">
      
                <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                    <div class="main-block-innner mb40 mt40 setting-page">
                        <ul>
                            <li class="clearfix">
                                <h5 class="f-left" style="color:black">MY PROFILE</h5> <a [routerLink]="['/my-profile']" routerLinkActive="router-link-active"  class="pull-right"><em class="fas fa-arrow-right"></em></a></li>
                            <li class="clearfix">
                                <h5 class="f-left" style="color:black">WITHDRAWAL FEE</h5> <a [routerLink]="['/withdrawl-fee']" routerLinkActive="router-link-active"  class="pull-right"><em class="fas fa-arrow-right"></em></a></li>
                         
                            <li class="clearfix">
                                <h5 class="f-left" style="color:black">WITHDRAWAL LIMIT</h5> <a [routerLink]="['/withdrawl-limit']" routerLinkActive="router-link-active"  class="pull-right"><em class="fas fa-arrow-right"></em></a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </main>
        <!-- Middle Content End -->
      </div>

      <footer>
        <div class="container-fluid">
            <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
                </p>
        </div>
    </footer>