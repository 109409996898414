import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators,FormArray,FormBuilder } from '@angular/forms';
declare var $:any

@Component({
  selector: 'app-wallet-types',
  templateUrl: './wallet-types.component.html',
  styleUrls: ['./wallet-types.component.css']
})
export class WalletTypesComponent implements OnInit {
  pageNumber: number=1;
  coinData: any=[];
  coinName: any=[];
  coin: any=[];
  coinShortName: any;
  fiatwallet: any=[];
  walletForm: FormGroup;
  callCenterArr: FormArray;
  walletImage: any="assets/img/alende.png";
  editwalletForm: FormGroup;
  editImage: any;
  
  constructor( private router : Router, public service:MainService,private fb:FormBuilder) { }

  ngOnInit(): void {
    this.coinList()
    this.walletForm = this.fb.group({
      coinName: new FormControl(''),
      image: new FormControl(''),
      iban: new FormControl(''),
      swift: new FormControl('')
    });
    this.editwalletForm = this.fb.group({
      coinName: new FormControl(''),
      image: new FormControl(''),
      iban: new FormControl(''),
      swift: new FormControl('')
    });
  }

  
   // coin list api
   coinList(){
    var url = "wallet/coin/get-coin-list?page=" +(this.pageNumber-1)+ "&pageSize=10" ;
    this.service.get(url).subscribe((res:any)=>{
      console.log('df', res);
      if(res['status']==200){
        this.coinData=res.data
        for(let item of this.coinData){
          
            this.fiatwallet = item.coinShortName
             
        }

        // this.coinData.forEach((element,i) => {
        //   this.coinData = element.coinShortName
        // });
      //   if (this.coinData.coinType == "fiat") {
      //   this.coinData.forEach((element) => {
      //     this.fiatwallet.push({
      //       walletcoin:element.coinShortName
      //     })
      //   });
      // }
        // for (let item of this.coinData) {
        //   if (item.coinType == "fiat") {
        //     this.fiatwallet = item.coinShortName
        //   }
        // }
        console.log("Fiat wallet--->>>", this.fiatwallet)
       
      }
      
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    });
    

  }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.walletImage = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  addFiatWallet(){
    let data={
      "coinShortName": this.walletForm.value.coinName,
      "coinImage":this.walletImage,
      "ibanNo": this.walletForm.value.iban,
      "swiftNo": this.walletForm.value.swift
    }
    this.service.showSpinner();
    this.service.post('wallet/admin/add-fiat-wallet', data).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc('Wallet added successfully');
        
      } 
      this.walletForm.reset()
      this.coinList()
  })
  }

  uploadeditImg($event): void {
    var img = $event.target.files[0];
    this.uploadeditImageFunc(img);
  }
  uploadeditImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.editImage = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  coinclicked(coin){
    this.service.get(`/wallet/coin/get-coin-details?coinName=${coin}`).subscribe((res:any)=>{
      if(res['status']==200){
            this.editImage=res.data.coinImage
            this.editwalletForm.patchValue({
              'coinName':res.data.coinShortName,
            })  
      }
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    });
    this.service.get(`wallet/wallet/bank/get-admin-bank-account?tokenName=${coin}`).subscribe((res:any)=>{
      if(res['status']==200){
        for(let items of res.data){
          this.editwalletForm.patchValue({
            'iban':items.ibanNo,
            'swift':items.swiftNo
          })  
        }
           
      }
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    });
  }

  editWallet(){
    let data={
      "coinShortName": this.editwalletForm.value.coinName,
      "coinImage":this.editImage,
      "ibanNo": this.editwalletForm.value.iban,
      "swiftNo": this.editwalletForm.value.swift
    }
    this.service.showSpinner();
    this.service.post('wallet/admin/add-fiat-wallet', data).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 205) {
        $('#coinModal').modal('hide')
        this.service.toasterSucc('Wallet added successfully');
      } 
      this.editwalletForm.reset()
      this.coinList()
  })
  }

}
