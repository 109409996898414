import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taker-make-fee',
  templateUrl: './taker-make-fee.component.html',
  styleUrls: ['./taker-make-fee.component.css']
})
export class TakerMakeFeeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
