
 <main class="middle-content">
  <div class="content-section">
      <div class="page_title_block pL0 pR0">
          <h1 class="page_title" style="color:#26272d">USER DETAIL</h1>
      </div>
      <div class="custom_tabs common-tabs">
          <div class="row mb20 justify-content-center">
              <div class="col-sm-12">
                  <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                      <li class="nav-item flex-fill"> <a class="nav-link active show"
                              [ngClass]="{'active': currTab=='GI'}" (click)="selectTab('GI')"
                              href="javascript:;">General Information</a>
                      </li>
                      <li class="nav-item flex-fill"> <a class="nav-link active show"
                              [ngClass]="{'active': currTab=='WalletTrading'}" (click)="selectTab('WalletTrading')"
                              href="javascript:;">Wallet Trading</a>
                      </li>
                      <li class="nav-item flex-fill"> <a class="nav-link active show"
                        [ngClass]="{'active': currTab=='Trading'}" (click)="selectTab('Trading')"
                        href="javascript:;">Trading</a>
                </li>
                <li class="nav-item flex-fill"> <a class="nav-link active show"
                  [ngClass]="{'active': currTab=='Login'}" (click)="selectTab('Login')"
                  href="javascript:;">Login Session Activity</a>
          </li>
                  </ul>
              </div>
          </div>
        </div>

        <!-- tab General -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'GI'">
          <div class="order-view mt30 max-WT-700 mrgn-0-auto">
              <div class="main-block-innner mb40 mt40">
                  <div class="add-store-block input-style">
                      <div class="form-group row">
                          <div class="col-md-12" style="text-align: center">
                              <img [src]="userDetail?.imageUrl || 'assets/img/testimonial-img1.jpg'" alt="">
                          </div>
                      </div>
                      <div class="form-group row align-items-baseline">
                          <label class="col-md-4">Name</label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-7">
                              <label>{{userDetail?.firstName}} {{userDetail?.lastName}}</label>
                          </div>
                      </div>
                      <div class="form-group row align-items-baseline">
                          <label class="col-md-4">Email</label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-7">
                              <label class="user-eml">{{userDetail?.email  || '---'}}</label>
                          </div>
                      </div>
                      <div class="form-group row align-items-baseline">
                          <label class="col-md-4">Phone Number</label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-7">
                              <label>{{userDetail?.phoneNo  || '---'}}</label>
                          </div>
                      </div>
                      

                      <div class="form-group row align-items-baseline">
                          <label class="col-md-4">Two Factor Type</label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-7">
                              <label>{{userDetail?.twoFaType}}</label>
                          </div>
                      </div>

                      <div class="form-group row align-items-baseline">
                          <label class="col-md-4">Address </label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-7">
                              <p class="rap2">{{userDetail?.address || '---'}}</p>
                          </div>
                      </div>

                      <div class="form-group row align-items-baseline">
                          <label class="col-md-4">KYC Doc1 </label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-7">
                              <p class="rap2">{{userKyc?.docName || '---'}}</p>
                          </div>
                      </div>
                      <div class="form-group row align-items-baseline">
                          <label class="col-md-4">KYC DocID </label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-7">
                              <p class="rap2">{{userKyc?.docIdNumber || '---'}}</p>
                          </div>
                      </div>
                      
                      


                  </div>
                  <div class="text-left mt40 text-center">
                      <a routerLink="/user-management" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                  </div>
              </div>
          </div>
      </div>
      
      <!-- tab genral end -->

      <!-- tab trading  -->
      <div class="tab-pane active show" *ngIf="this.currTab == 'Trading'">
          
                      <div class="content-section">
                          <div class="outer-box">
                              <div class="global-table no-radius p0">
                                  <div class="tab-content1">
                                      <div class="tab-pane1">
                                        
                                        <!-- ******************** This is the filter section start here ********************* -->
                                          <div class="user-main-head sec_head_new sec_head_new_after" >
                                              <form [formGroup]="walletForm">
                                                  <div class="row">
                                                    <div class="col-md-2">
                                                      <div class="head_flt_select">
                                                        <div class="head_flt_select input-label input-label1">
                                                            <span class="d-flex align-items-center" >Type : </span>
                                                            <select formControlName="type" name="" id="" class="w-100 form-control">
                                                              <option value="">--SELECT--</option>
                                                              <option value="BUY">BUY</option>
                                                              <option value="SELL">SELL</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                      <div class="head_flt_select">
                                                        <div class="head_flt_select input-label input-label1">
                                                            <span class="d-flex align-items-center" >Currency : </span>
                                                            <select formControlName="currency" name="" id="" class="w-100 form-control">
                                                              <option value="">COIN</option>
                                                              <option *ngFor="let coins of coinData" value={{coins?.coinShortName}}>{{coins?.coinShortName}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    </div>
                                                      <div class="col-md-5">
                                                          <div class="row">
                                                              <div class="col-md-6">
                                                                  <div class="head_flt_select">
                                                                      <div class="head_flt_select input-label input-label1">
                                                                          <span class="d-flex align-items-center" >From Date : </span>
                                                                          <input style="margin-bottom:2%" type="date" formControlName="startdate"
                                                                          class="form-control ng-untouched ng-pristine ng-invalid">
                                                                      </div>
                                                                  </div>
                    
                                                              </div>
                                                              <div class="col-md-6">
                    
                                                                  <div class="head_flt_select">
                                                                      <div class="head_flt_select input-label input-label1">
                                                                          <span class="d-flex align-items-center">To Date : </span>
                                                                          <input type="date" formControlName="enddate" class="form-control ng-untouched ng-pristine ng-invalid">
                                                                      </div>
                                                                  </div>
                    
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-3">
                                                          <div class="user-form2">
                                                              <div class="row">
                                                                  <div class="col-md-12 user-lst">
                                                                      <div class="two-btnline">
                                                                            <button type="submit" class="btn  btn-theme cus-mr" (click)="search()">SEARCH</button>
                                                                              <button type="submit" class="btn  ml-1 btn-theme cus-mr"
                                                                                (click)="reset()"  >RESET</button>
                    
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </form>
                                          </div>
                          <!-- ******************** This is the filter section end here ********************* -->
                    
                          <div class="custom_tabs common-tabs">
                            <div class="row mb20">
                              <div class="col-sm-9">
                    
                              </div>
                                <div class="col-sm-3 text-right">
                                  <div class="head_flt_select">
                                    <div class="head_flt_select input-label">
                                        <button type="submit" class="btn  btn-theme" (click)="exportAsXLSX()">EXPORT AS EXCEL </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                          </div>
                    
                                          <div class="table-responsive">
                                              <table class="table table-bordered" aria-describedby="user list">
                                                  <thead>
                                                      <tr class="no_wrap_th">
                                                          <th scope="col">S.No</th>
                                                          <th scope="col">Transaction ID                                      </th>
                                                          <th scope="col">Transaction Type</th>
                                                          <th scope="col">Amount</th>
                                                          <th scope="col">Base Coin</th>
                                                          <th  scope="col">Executable Coin</th>
                                                          <th  scope="col">Date and Time</th>
                                                          <th scope="col" class="action_td_btn3">Action</th>
                    
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr *ngFor="let data of tradingDetail | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:tradingDetail?.length};let i=index">
                                                        <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                                        <td>{{data?.basicTradeHistoryId}}</td>
                                                        <td>{{data?.orderType}}</td>
                                                        <td>{{data?.baseCoinAmmount}}</td>
                                                        <td>{{data?.baseCoinName}}</td>
                                                        <td>{{data?.execCoinName}}</td>
                                                        <td>{{data?.creationTime | date}}</td>
                                                        <td class="action_td_btn3" style="cursor: pointer">
                                                          <a (click)="viewUserTrading(data?.basicTradeHistoryId)"><i class="fa fa-eye" aria-hidden="true"
                                                            ></i></a>
                                                        </td>
                  
                                                    </tr>
                                                      <tr *ngIf="tradingDetail?.length==0">
                                                        <td colspan="10" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5 style="margin-left: 44%;">No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                  </tbody>
                                              </table>
                                          </div>
                                          <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" *ngIf="tradingDetail?.length>20">
                                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                                        </div>
                                      </div>
                                  </div>
                                 
                             
                    </div>
                  </div>
                 </div>
               </div>
      <!-- tab trading end-->

      <!-- tab login session -->
    
      <div class="tab-pane active show" *ngIf="this.currTab == 'Login'">
      <div class="order-view mt30 max-WT-700 mrgn-0-auto">
          <div class="main-block-innner mb40 mt40">
              <div class="add-store-block input-style"> 
          <div class="form-group row">
            <label class="col-md-5 text" for="">Last login</label>
            <span class="col-md-1">:</span>
            <p class="col-md-6">{{loginSessionData?.lastLogin | date}} </p>
        </div>
        <div class="form-group row">
          <label class="col-md-5 text" for="">Location</label>
          <span class="col-md-1">:</span>
          <p class="col-md-6">{{loginSessionData?.location}}</p>
      </div>
      
    <div class="form-group row">
      <label class="col-md-5 text" for="">IP Address</label>
      <span class="col-md-1">:</span>
      <p class="col-md-6">{{loginSessionData?.ipAddress}}</p>
  </div>
    <div class="form-group row">
      <label class="col-md-5 text" for="">Last Transaction</label>
      <span class="col-md-1">:</span>
      <p class="col-md-6">{{loginSessionData?.lastTransactionDate | date}}</p>
  </div>

    </div>
   </div>
  </div>  
 </div> 

 <footer>
    <div class="container-fluid" >
        <p class="copyright" style="margin-right: 50%">Alende  <strong class="theme-text-color"></strong> 
            </p>
    </div>
</footer>

      <!-- tab login session end -->

          
      </div>
    </main>
