<div class="wrapper">
    
    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View Faq</h1>
           
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                      <form [formGroup]="viewFaqForm">
                        <div class="form-group row ">
                            <label class="col-md-4">Question :</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control " formControlName="title" (keypress)="service.preventSpace($event)" disabled/>

                                <span class="error">
                                  <p *ngIf="viewFaqForm.get('title').hasError('required') && (viewFaqForm.get('title').dirty || viewFaqForm.get('title').touched )"
                                      class="error" padding>*Field is required</p>
                              </span> 
                              <span class="error">
                                      <p *ngIf="viewFaqForm.get('title').hasError('pattern') && (viewFaqForm.get('title').dirty || viewFaqForm.get('title').touched )"
                                          class="error" padding>*Please enter valid question</p>
                                  </span>
                            </div>

                        </div>
                        <div class="form-group row">
                    
                            <label class="col-md-4">Answer:</label>
                            <div class="col-md-8" class="ex1" style="width: 66%;
                            margin-left: 36%;" >
                              <textarea  class="ex1" class="form-control" style="height: 500px" cols="150" rows="5" formControlName="description" (keypress)="service.preventSpace($event)" disabled></textarea>
                              
                          </div>
                        </div>
                        <div class="text-center mt40">
                           
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/faq-management']">Cancel</a>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
  <div class="container-fluid">
      <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
          </p>
  </div>
</footer>
